.menuUser{
    min-width: 150px;
}

.text-height-0 {
    line-height: 1 !important;
}

.text-height-1 {
    line-height: 1.5 !important;
}