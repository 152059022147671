.indexTable td {
    padding: 0.2em 0.5em;
}
.indexTable th {
    padding: 0.5em 0.5em;
}
th.col-action {
    width: 300px;
}

td.col-action {
    width: 300px;
    display: flex;
    justify-content: center;

    a {
        margin: 0 3px;
    }
}

svg.sorticon {
    height: 0.75rem;
    width: 0.75rem;
}

/* Table sort indicators */

th.sortable {
    position: relative;
    cursor: pointer;
}

.btn-label, .btn-label:hover {
    cursor: default;
    opacity: .65;
    color: #2b2b2b;
    border-color: #6c757d;
}
