// Fonts
// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic');
@import url("https://fonts.googleapis.com/css?family=Nunito");

// fontawesome
$fa-font-path: "../webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";

// OverlayScrollbars
@import "~overlayscrollbars/css/OverlayScrollbars.css";

// iCheck
@import "~icheck-bootstrap/icheck-bootstrap.css";

// Variables
@import "variables";

//Admin Lte 3
@import "~admin-lte/dist/css/adminlte.css";
@import "~admin-lte/plugins/select2/css/select2.css";
@import "~admin-lte/plugins/daterangepicker/daterangepicker.css";
@import "~admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.css";
// @import "~admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.css";
// @import '~admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css';
@import "~admin-lte/plugins/chart.js/Chart.css";

@import "~tempusdominus-bootstrap-4/src/sass/tempusdominus-bootstrap-4-build";



@import "dashboardGrid";

@import "adminLteOverride";
@import "tables";
@import "layout";
