.login-box {
    width: 400px;
}

.register-box {
    width: 500px;
}

.complete-profile-box {
    width: 750px;
}

.select2-container--default .select2-selection--single {
    padding: 0.1rem 0.1rem;
}

/* remove arrow / spinner in number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.info-box-number {
    font-size: xx-large;
    // margin-top: 0.4em !important;
    line-height: normal;
}
.info-box-text {
    white-space: inherit !important;
    line-height: normal;
}

ul.pagination {
    margin-bottom: 0;
}

.toasts-top-right {
    position: absolute;
    right: 12px;
    top: 68px;
    z-index: 1040;
}

.toast-body {
    padding: 0.5rem;
}

.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0;
    font-size: 80%;
    color: #dc3545;
}

blockquote {
    background: #ffffff;
    border-left: 0.2rem solid #007bff;
    margin: 1.5em 0.7rem;
    padding: 0.5em 0.7rem;
}

.bg-light-gray {
  background-color: #e9ecef !important;
}
